


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter';
}


.modern-button {
  width: 100%;
  border: 1px solid #2e2e32;
  padding: 20px;
  background-color: #202127;
  border-radius: 50px; 
  color: white;
  font-size: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease; 
  cursor: pointer;
  outline: none;
}

.modern-button:hover, .modern-button:focus {
  background-color: #2e2e32; 
  outline: none;
}


body, html {
  height: 100vh;
  margin: 0;
  background-color: black;
  color: white;
  font-family: 'Manrope';
  
}


.App {
  display: flex;
  min-height: 100vh; 
  max-width: 1300px;
  margin: auto; 
  border: 1px solid rgb(46, 46, 50);
}


.left-sidebar {
  width: 250px; 
  height: 100vh;
  overflow-y: auto; 
  position: sticky;
  top: 0;
  border: 1px solid rgb(46, 46, 50);
  
}


.left-sidebar {
  position: sticky;
  top: 54px; 
  height: calc(100vh - 60px); 
  overflow-y: auto;
  padding-top: 30px;
}

.main-content {
  overflow-y: auto;
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}


.course-page-content {
  margin-right: 0px;
}

.main-content {
  margin-top: 0px; 
}

.home-content {
  margin: 20px; 
  max-width: 100%; 
}

.body {
 line-height: 1.4;
 font-family: 'Manrope';
 font-weight: 500;
 margin-bottom: 30px;
}

h1 {
  font-weight: 600;
  font-size: 50px;
  letter-spacing: -0.05em;
}

.cards-home {
  display: block;
}

.banner-text {
  font-size: 40px;
}

.body-copy {
 color: grey;
 font-size: 20px;
 font-weight: 500;
 padding-top: 30px;
}

h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
}


.main-content {
  flex-grow: 1; 
  max-width: 1045px;
  overflow-x: hidden; 
  
}

.side-navbar ul {
  list-style-type: none;
}

.side-navbar ul li a {
  text-decoration: none;
  color: rgb(190, 190, 190);
  display: block;
  padding: 10px 20px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease; 
  border-radius: 70px;
  font-weight: 600;
  font-size: 0.875rem;
}

.sidebar-subheader {
  text-decoration: none;
  color: rgb(255, 255, 255);
  display: block;
  padding: 10px 20px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease; 
  border-radius: 0px;
  font-weight: 900;
}

.side-navbar ul li a:hover, .side-navbar ul li a.active-link {
  background-color: #0d0d0d; 
  color: rgb(190, 190, 190); 
  border-radius: 7px;
}

.side-navbar ul li a:focus, .side-navbar ul li a.active-link {
  background-color: #1a1a1a; 
  color: rgb(255, 255, 255); 
  border-radius: 7px;
}


.card {
  background-color: #333; 
  color: white; 
  margin-bottom: 20px; 
  padding: 20px; 
  border-radius: 8px; 
}

.label {
  display: inline-block;
  background-color: #FFD700; 
  color: black; 
  padding: 5px 10px;
  border-radius: 4px; 
  margin-bottom: 10px; 
}

.fade-in-wrapper {
  animation: fadeIn 1s ease-out;
}

.navbar-content-wrapper {
 display: flex;
 justify-content: space-between;
 padding-left: 40px;
 border-bottom: 1px solid rgb(46, 46, 46);
 height: 71px;
 position: sticky;
 background-color: black;
}


.rounded {
  background-color: black; 
  border: 1px solid grey; 
  color: white; 
  max-height: 100%; 
  overflow: visible; 
  white-space: pre-wrap; 
  word-break: break-word; 
  font-size: 13px; 
  resize: none; 
  border-radius: 30px;
  padding: 30px;
}


.navbar-content { 
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1300px; 
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2e2e32;
  background-color: black;
  padding: 10px 20px; 
  border: 1px solid rgb(46, 46, 50);
}


.navbar {
  width: 100%;
  z-index: 1000; 
  border-radius: 100px;
  
}

.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  width: 100%; 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; 
  background-color: #161618; 
  
}

pre.html {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}




.navbar {
  display: flex;
  background: black; 
  align-items: center;
  justify-content: flex-start;
}

.nav-button {
  color: white;
  font-weight: 500;
  border: none;
  padding: 0 20px; 
  height: 56px; 
  line-height: 56px; 
  font-size: 16px; 
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1; 
  background-color: black;
}

.nav-button-2 {
  color: white;
  font-weight: 500;
  border: none;
  padding: 0 20px; 
  height: 56px; 
  font-size: 16px; 
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1; 
  background-color: black;
}

.nav-button-3 {
  color: white;
  font-weight: 500;
  border: none;
  padding: 0 20px; 
  height: 56px; 
  line-height: 56px; 
  font-size: 16px; 
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1; 
  background-color: black;
}

.nav-button:hover {
  border-bottom: 1px solid white;
}

.nav-button-2:hover {
  border-bottom: 1px solid white;
}

.nav-button-3:hover {
  border-bottom: 1px solid white;
}

.nav-button:focus {
  outline: none;
}

svg {
  z-index: 0; 
}








@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {

  .left-sidebar {
    width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .navbar {
      flex-direction: row;
  }

  .side-navbar {
    
    display: none;
  }

  .left-sidebar {
    width: 0px;
    display: none;
  }

  .course-page-content {
    margin: 0px;
  }

  .main-content {
    max-width: 1500px;
  }

  .right-container {
   display: none;
  }

  .menu {
    display: none;
   }

   .search-input {
    display: none;
   }

  .banner-text {
    font-size: 30px;
  }

  .navbar-content-wrapper {
    padding-left: 0px;
  }

  .banner-icon {
    display: none;
  }


  
}


